import api from '@/services/api'

const defaultevents = {
	'Last': {
		'2019-11-08': [
			{
				id: 'e1',
				start: '2019-11-08 09:00',
				duration: 30,
				state: 'new',
				title: 'Event 22222',
				color: '#45b8ee',
				client: {
					name: 'mon client',
					address: 'zare ouest',
					zipcode: '4384',
					city: 'Ehlerange'
				},
				showactions: false
			},
			{
				id: 'e2',
				start: '2019-11-08 09:00',
				duration: 30,
				state: 'new',
				title: 'Event 3333',
				color: '#eeaa2c',
				client: {
					name: 'mon client',
					address: 'zare ouest',
					zipcode: '4384',
					city: 'Ehlerange'
				},
				showactions: false
			}
		],
		'2019-11-09': [
			{
				id: 'e3',
				start: '2019-11-09 10:00',
				duration: 60,
				state: 'new',
				title: 'Event 1111',
				color: '#eee',
				client: {
					name: 'mon client',
					address: 'zare ouest',
					zipcode: '4384',
					city: 'Ehlerange'
				},
				showactions: false
			}
		]
	},
	'Today': {
		'2019-11-11': [
			{
				id: 'e4',
				start: '2019-11-11 10:00',
				duration: 60,
				state: 'new',
				title: 'Event 1111',
				color: '#eee',
				client: {
					name: 'mon client',
					address: 'zare ouest',
					zipcode: '4384',
					city: 'Ehlerange',
					email: 'contact@cosnelio.oicm',
					phone: '0303030303'
				},
				actions: [
					{
						name: 'intervention',
						form_id: 'INF-A7-1-TCOX4',
						status: 'end'
					}
				],
				showactions: false
			}
		]
	},
	'Next': {}
};

export default {
	namespaced: true,
	state: {
		events: null,
		periodFilter: 'Today',
		showed: {}
	},
	mutations: {
		setevents(state, events) {
			console.log('setevents', events);
			state.events = events
		},
		setshowactions(state, {uid, value}) {
			state.showed[uid] = value;
		},
		setPeriodFilter(state, value) {
			state.periodFilter = value;
		}
	},
	actions: {
		setPeriodFilter : ({commit, state}, value) => {
			commit('setPeriodFilter', value);
		},
		setShowactions : ({commit, state}, {uid, value}) => {
			commit('setshowactions', {uid, value})
		},
		async refresh({commit, state, dispatch}) {
			
			try {
				let call = await api.get({token: null, url: 'intervention/v2/missions'})
				
				let events = call.response;
				let forms = [];
				Object.keys(events).map(function(periode, index) {
					Object.keys(events[periode]).map(function(date, index) {
						Object.keys(events[periode][date]).map(function(key) {
							events[periode][date][key]['showactions'] = !!state.showed[events[periode][date][key].uid];
							events[periode][date][key].actions.forEach(function(action){
								if (forms.indexOf(action.form_id) == -1) {
									forms.push(action.form_id);
								}
							})
						})
					})
				});
				if (forms.length){
					dispatch('forms/refresh', forms, {root:true});
				}
				
				commit('setevents', events);
			} catch (err) {
				console.log('forms refresh', err);
			}
		}
	},
	modules: {},
	getters: {
		getEvents: (state) => (period) => {
			return state.events && state.events[period] ? state.events[period] : {};
		},
		getAllUid: (state) => {
			let events = state.events;
			let ids = [];
			Object.keys(events).forEach(period => {
				Object.keys(events[period]).forEach(date => {
					events[period][date].forEach(e => {
						ids.push(e.uid);
					})
				});
			});
			return ids;
		},
		getPeriodFilter: (state) => {
			return state.periodFilter;
		},
		getEvent: (state) => (id) => {
			let events = state.events;
			let event;
			Object.keys(events).forEach(period => {
				if (!event) {
					Object.keys(events[period]).forEach(date => {
						if (!event) {
							event = events[period][date].find(evt => evt.uid == id);
						}
					});
				}
			});
			return event;
		}
	}
}
