<template>
	<div>
		<template v-for="(events, date) in getEvents(period)">
			<ul class="list-group mb-3">
				<div class="font-size-h3 mb-2">{{date | formatDate}}</div>
				<template v-for="event in events">
					<li @click="clickEvent(event)"
						:key="event.uid" class="list-group-item shadow"
						:style="{'border-left-color': event.color}">
						<div class="d-flex justify-content-between">
							<div><span class="font-weight-bold">{{event.start | formatTime}}</span> ({{event.duration}}) - {{event.typelibelle}}
							</div>
							<div class="font-size-sm">
								<span>{{event.state}}</span>
								<span class="badge badge-pill ml-2" :class="{'badge-danger': getSyncStatus(event.uid)===0, 'badge-success': getSyncStatus(event.uid)===1, 'badge-warning': getSyncStatus(event.uid)===2}">&NoBreak;</span>
							</div>
						</div>
						<div v-if="event.client.name">
							{{event.client.name}}<template v-if="event.client.addressname"> - {{event.client.addressname}}</template>
						</div>
						<div>
							{{event.title}}
						</div>
						<div v-if="event.client.address">
							{{event.client.address}} {{event.client.zipcode}} {{event.client.city}}
						</div>
					</li>
				</template>
			
			
			</ul>
		</template>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex';
	
	export default {
		name: 'ListEvents',
		props: ['period'],
		data: () => {
			return {
				sheet: false
			};
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('events', [
				'getEvents',
			]),
			...mapGetters('interventions', [
				'getSyncStatus',
			])
		},
		mounted: async function(){
			await this.$store.dispatch('events/refresh')
		},
		methods: {
			...mapActions('events', ['setShowactions']),
			clickEvent: function (event) {
                this.$router.push({name: 'event', params: {eventid: event.uid}});
			}
		}
	};
</script>

<style>
	.home .list-group a.list-group-item {
		color: inherit;
	}
	
	.home .list-group .list-group-item {
		border: none;
		border-left: 1px solid black;
		border-left-width: 10px;
		border-radius: 0;
		margin-bottom: 5px;
	}
</style>
