<template>
	<div class="h-100">
		<div class="block block-fx-shadow" v-if="event">
			<div class="block-content block-content-full info py-2"
				 :style="{'border-left-color': event.color}">
				<div class="font-size-h3">{{event.client.name}}<template v-if="event.client.addressname"> - {{event.client.addressname}}</template></div>
				<p class="mb-0" v-if="event.typelibelle">{{event.typelibelle}}</p>
				<p v-if="event.title"v-html="$options.filters.nl2br(event.title)"></p>
				<p v-if="event.comment" v-html="$options.filters.nl2br(event.comment)"></p>
				
				<div class="row" v-if="event.client.address">
					<div class="col py-1">
						<link-address :lat="event.lat" :lng="event.lng"
								v-model='event.client.address+" "+event.client.city'>
							{{event.client.address}}<br>
							{{event.client.zipcode}} {{event.client.city}}
						</link-address>
					</div>
				</div>
				<div class="row" v-if="event.client.phone">
					<div class="col py-1">
						<tel v-model="event.client.phone"></tel>
					</div>
				</div>
				<div class="row" v-if="event.client.mobile">
					<div class="col py-1">
						<tel v-model="event.client.mobile"></tel>
					</div>
				</div>
				<div class="row">
					<div class="col py-1">
						<email v-model="event.client.email"></email>
					</div>
				</div>

				<div v-if="event.contacts" v-for="c in event.contacts">
					<div class="font-size-h5 mt-2 font-weight-bold">{{c.name}}</div>
					<div class="row" v-if="c.phone">
						<div class="col py-1">
							<tel v-model="c.phone"></tel>
						</div>
					</div>
					<div class="row" v-if="c.mobile">
						<div class="col py-1">
							<tel v-model="c.mobile"></tel>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block block-fx-shadow" v-if="event&&event.parc.name">
			<div class="block-content block-content-full py-2">
				<div class="font-size-h4">{{event.parc.name}}</div>
				<p class="mb-0" v-if="event.parc.type">{{event.parc.type}}</p>
				<p class="mb-0" v-if="event.parc.marque||event.parc.modele">{{event.parc.marque}} {{event.parc.modele}}</p>
				<p class="mb-0" v-if="event.parc.nserie">{{event.parc.nserie}}</p>
				<p class="mb-0" v-if="event.parc.date_mes"><span class="h6">MES</span> : {{event.parc.date_mes| formatDate}}</p>
				
				<p class="mb-0" v-if="event.parc.others.length" v-for="(cp,k) in event.parc.others" :key="`parc-cp-`+k">
					<span class="h6">{{cp.libelle}}</span> : <span v-html="cp.valeur"></span>
				</p>
			</div>
		</div>
		<div class="block block-fx-shadow" v-if="event&&event.contrat.name">
			<div class="block-content block-content-full py-2">
				<div class="font-size-h4">{{event.contrat.name}}</div>
				<p class="mb-0" v-if="event.contrat.type">{{event.contrat.type}}</p>				
				<p class="mb-0" v-if="event.contrat.others.length" v-for="(cp,k) in event.contrat.others" :key="`contrat-cp-`+k">
					<span class="h6">{{cp.libelle}}</span> : <span v-html="cp.valeur"></span>
				</p>
			</div>
		</div>
		<!-- Liste les fichiers dispo sur le RDV -->
        <template v-if="event.fichiers&&event.fichiers.length">
            <div class="h4 mb-1">Pièces jointes</div>
			<ul class="list-group">
				<a href="#" v-for="fic in event.fichiers" :key="`fichier-`+eventid+`-`+fic.id" class="list-group-item list-group-item-action"
					@click.prevent="downloadFile(fic.id, fic.libelle)">{{ fic.libelle }}</a>
			</ul>
		</template>
		<div class="row text-center my-4" v-if="event">
			<div class="col-4">
				<i class="fal fa-2x fa-stopwatch"></i><br>{{event.duration}}
			</div>
			<div class="col-4">
				<i class="fal fa-2x fa-calendar-alt"></i><br>{{event.start | formatDate}}
			</div>
			<div class="col-4">
				<i class="fal fa-2x fa-clock"></i><br>{{event.start | formatTime}}
			</div>
		</div>

<!--		Liste des responses déjà présentes-->
		<div class="mt-4" v-if="Object.keys(intervention.responses).length">
            <div class="h4 mb-1">Formulaire(s) déjà ajouté(s)</div>
            <div v-for="(reponses,k) in intervention.responses" :key="k" class="d-flex mt-2">
                <div class="flex-grow-1">
                    <a class="btn btn-block btn-lg" @click.prevent="continueReponse(k)"
                       :class="{'btn-success':reponses.finished, 'btn-warning':!reponses.finished}">
                        {{ reponses.action.name }}
                    </a>

                </div>
                <div class="ml-4">
                    <a href="#" class="font-size-lg" @click.prevent="removeResponses(k)"><i class="far text-danger fa-trash-alt"></i></a>
                </div>
            </div>
        </div>
        <template v-if="event.actions.length">
            <a href="#" class="btn btn-block btn-lg btn-outline-info mt-4" @click.prevent="showActions = true" v-if="!showActions">Ajouter un formulaire</a>
            <div v-if="showActions" class="mt-4">
                <div class="h4 mb-1">Choisissez un formulaire</div>
                <a href="#" v-for="(action,k) in event.actions" :key="`buttons-`+k" class="btn btn-block btn-lg btn-info text-capitalize"
                @click.prevent="createResponseAndRoute(action)">{{action.name}}</a>
            </div>
        </template>

        <template v-if="event.buttons.length>0">
			<div v-if="hasResponseNotFinished" class="alert alert-warning mt-4">Un rapport n'est pas terminé, changement d'état impossible</div>
			<template v-else>
				<div class="h4 mt-4 mb-1">J'ai fini l'intervention et je souhaite changer son état</div>
				<a href="#" v-for="button in event.buttons" @click.prevent="closeIntervention(button.etat_id)"
				class="btn btn-block btn-lg" :class="`btn-`+button.color">
					{{button.name}}
				</a>
			</template>
        </template>
        <br>
	</div>
</template>


<script>
	
	// @ is an alias to /src
	import {mapActions, mapGetters} from 'vuex'
	import Tel from '@/components/Tel.vue';
	import Email from '@/components/Email.vue';
	import LinkAddress from '@/components/LinkAddress.vue';

	import api from '@/services/api'
	
	export default {
		name: 'event',
		props: ['eventid'],
		components: {
			Tel, Email, LinkAddress
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('events', [
				'getEvent'
			]),
			...mapGetters('interventions', [
				'getIntervention'
			]),
			hasResponseNotFinished(){
				return Object.keys(this.intervention.responses).length > 0 && _.filter(this.intervention.responses, {finished: false}).length>0;
			}
		},
		beforeMount: function () {
			this.event = this.getEvent(this.eventid);
			this.intervention = this.getIntervention(this.eventid);
			if (Object.keys(this.intervention.responses).length==0) {
			    this.showActions = true;
            }
		},
		data: function () {
			return {
				event: {},
				intervention: {},
                showActions: false,
			}
		},
        methods: {
			...mapActions('interventions', ['finishIntervention', 'addResponse', 'removeResponse']),
		    closeIntervention(etatid){
			    Dashmix.layout('header_loader_on');
		        this.finishIntervention({uid: this.eventid, etatid: etatid});
		        this.$router.push({name: 'home'});
            },
            continueReponse(responseid){
			    Dashmix.layout('header_loader_on');
			    this.$router.push({name: 'resultMission', params:{responseid:responseid, eventid: this.eventid}});
            },
            async createResponseAndRoute(action) {
			    Dashmix.layout('header_loader_on');
				let prefilled = {};
				
				if (this.event['pre-filled'] && this.event['pre-filled'][action.form_id]) {
					prefilled = this.event['pre-filled'][action.form_id];
				}
				
				let responseid = await this.addResponse({uid: this.eventid, action: action, prefilled:prefilled});

			    this.$router.push({
                    'name': 'resultMission',
                    'params': {
                        eventid: this.eventid,
                        responseid: responseid
                    }
                });
            },
            removeResponses(responseid) {
			    Dashmix.layout('header_loader_on');
                if(confirm('Voulez-vous supprimer ce formulaire ?')) {
                    this.removeResponse({uid: this.eventid, responseid:responseid})
					if (Object.keys(this.intervention.responses).length==0) {
						this.showActions = true;
					}
                }
			    Dashmix.layout('header_loader_off');
            },
			downloadFile(id, libelle){
				api.getFile({ url: 'intervention/fichiers/'+id+'/get', filename: libelle})
			}
        }
	}
</script>

<style>
	.block-content.info {
		border: none;
		border-left: 1px solid black;
		border-left-width: 10px;
		border-radius: 0;
		margin-bottom: 5px;
	}
</style>
